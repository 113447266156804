<template>
  <div class="library">
    <section class="main-content">
      <LibraryContentHeader
        :sorting="sorting"
        @sorting-change="onSortingChange"
        @search-update="fetchData(getJobs, fetchFilteredJobs, true)"
      />

      <LibraryItems
        v-if="jobs"
        :items="jobs"
        :library-type="LIBRARY_TYPE.JOB"
        :user="user"
        :download-callback="downloadAll"
        :loading="isLoading"
        @scroll-hit-bottom="onScrollHitBottom"
      />
    </section>
    <LibraryDetailJob
      v-if="detailId"
      :job-id="detailId"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import { JOB_STATUS } from '@/constants/loadingStatus'
import { DataUtils } from '@/mixins/utils.js'
import { FetchData } from '@/mixins/fetchData.js'
import LibraryDetailJob from '@/components/library/LibraryDetailJob'
import LibraryContentHeader from '@/components/library/LibraryContentHeader'

export default {
  name: 'RendersLibrary',

  components: {
    LibraryDetailJob,
    LibraryContentHeader
  },

  mixins: [DataUtils, FetchData],

  data () {
    return {
      LIBRARY_TYPE,
      jobUpdateTimeout: {},
      newJobsInterval: null,
      detailId: null
    }
  },

  computed: {
    ...mapGetters(['getJobs', 'getJobStatusById']),

    ...mapGetters({
      user: 'getCognitoUserData',
      sorting: 'getSortingJobs'
    }),

    /**
     * @returns {Array}
     */
    jobs () {
      const jobs = this.getJobs
      jobs.forEach(job => this.handleStatus(job))

      return jobs
    }
  },

  watch: {
    /**
     * @param {string} to
     * @param {string} from
     */
    $route (to, from) {
      this.parseUrlForDetail(this.$route)
    }
  },

  created () {
    this.fetchData(this.getJobs, this.fetchFilteredJobs)
    this.createNewJobsInterval()
    this.setActiveLibraryType(LIBRARY_TYPE.JOB)
  },

  mounted () {
    this.parseUrlForDetail(this.$route)
  },

  beforeDestroy () {
    // Kill all set Intervals
    window.clearInterval(this.newJobsInterval)
    for (const jobId in this.jobUpdateTimeout) {
      window.clearTimeout(this.jobUpdateTimeout[jobId])
    }
  },

  methods: {
    ...mapActions([
      'setActiveLibraryType',
      'setSortingJobs',
      'incrementPagingJobs',
      'fetchFilteredJobs',
      'updateJobStatus',
      'fetchNewJobs',
      'forceJobStatus',
      'getRendersDownloadURL'
    ]),

    /**
     * @param {string} url
     */
    parseUrlForDetail (url) {
      const detailId = this.$route.query.detail
        ? parseInt(this.$route.query.detail)
        : null

      this.detailId = detailId
    },

    /**
     * @param {object} item
     */
    async downloadAll (item) {
      const jobId = item.id

      if (this.getJobStatusById(jobId) === JOB_STATUS.DOWNLOAD) {
        return
      }

      let jobStatus = JOB_STATUS.DOWNLOAD
      this.forceJobStatus({
        jobId,
        jobStatus
      })

      const rendersDownloadUrl = await this.getRendersDownloadURL(jobId)
      jobStatus = JOB_STATUS.SUCCESS
      this.forceJobStatus({
        jobId,
        jobStatus
      })

      this.triggerDownload(rendersDownloadUrl)
    },

    /**
     * @param {object} job
     */
    handleStatus (job) {
      const jobStatus = this.getJobStatusById(job.id)

      if (this.isJobRunning(jobStatus)) {
        this.createUpdateJobInterval(job.id)
      }
    },

    /**
     * @param {number} jobId
     */
    createUpdateJobInterval (jobId) {
      if (this.jobUpdateTimeout[jobId]) {
        return
      }

      this.jobUpdateTimeout[jobId] = window.setTimeout(async () => {
        await this.updateJobStatus(jobId)
        const jobStatus = this.getJobStatusById(jobId)

        delete this.jobUpdateTimeout[jobId]

        if (this.isJobRunning(jobStatus)) {
          this.createUpdateJobInterval(jobId)
        }
      }, 3000)
    },

    /**
     */
    createNewJobsInterval () {
      window.clearInterval(this.newJobsInterval)
      this.newJobsInterval = window.setInterval(async () => {
        await this.fetchNewJobs()
      }, 5000)
    },

    /**
     */
    onScrollHitBottom () {
      this.incrementPagingJobs()
      this.fetchData(this.getJobs, this.fetchFilteredJobs, true)
    },

    /**
     * @param {string} changedSorting
     */
    onSortingChange (changedSorting) {
      if (this.sorting === changedSorting) {
        return
      }

      this.setSortingJobs({ sorting: changedSorting })
      this.fetchData(this.getJobs, this.fetchFilteredJobs, true)
    },

    /**
     * @param   {string}  jobStatus
     *
     * @returns {boolean}
     */
    isJobRunning (jobStatus) {
      return (
        [JOB_STATUS.SUCCESS, JOB_STATUS.FAILED].includes(jobStatus) === false
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.library {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.main-content {
  width: 100%;
  height: 100%;
  margin-top: spacing(4);
  padding-right: spacing(1);
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('StitchDialog',{class:[
    'library-detail-job',
    {
      'library-detail-job--error': _vm.show404 || _vm.showOtherErrors
    }
  ],attrs:{"visible":_vm.showDialog},on:{"update:visible":function($event){_vm.showDialog=$event},"close":_vm.onClose}},[(_vm.job)?[_c('div',{staticClass:"library-detail-job__title",attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.jobTitle)+" ")]),_c('div',{staticClass:"library-detail-job__content"},[_c('div',{staticClass:"library-detail-job__render-list"},_vm._l((_vm.renders),function(render,index){return _c('ElementCard',{key:index,staticClass:"library-detail-job__render",attrs:{"shadow":"hover","body-style":{ padding: '8px' }}},[_c('div',{staticClass:"library-detail-job__render-preview",style:({
              'background-image': ("url('" + (_vm.s3trim(
                render,
                _vm.previewResolution
              )) + "')")
            }),on:{"click":function($event){return _vm.triggerSingleRenderModal(render, index)}}}),_c('a',{staticClass:"library-detail-job__render-download",attrs:{"href":render,"download":"","role":"button"},on:{"click":function($event){return _vm.trackDownloads({ renderIndex: index })}}},[_c('i',{staticClass:"el-icon-download library-card-item__actions-download"})])])}),1)]),_c('div',{staticClass:"library-detail-job__actions",attrs:{"slot":"footer"},slot:"footer"},[_c('ElementButton',{attrs:{"type":"danger","plain":""},on:{"click":_vm.openConfirmDelete}},[_vm._v(" Delete Renders ")]),_c('ElementButton',{attrs:{"type":"primary","plain":""},on:{"click":_vm.downloadAll}},[_vm._v(" Download All Images ")])],1),_c('StitchDialog',{staticClass:"library-detail-job__single-job-preview",attrs:{"visible":_vm.showSingleRender,"append-to-body":"","top":"5vh"},on:{"update:visible":function($event){_vm.showSingleRender=$event},"closed":_vm.onSingleRenderClose}},[_c('div',{staticClass:"library-detail-job__single-job-preview-content"},[_c('img',{staticClass:"library-detail-job__single-job-image",attrs:{"src":_vm.s3trim(_vm.singleRenderUrl),"alt":"single render"}})])])]:(_vm.show404)?[_c('p',{staticClass:"library-detail-job__error"},[_vm._v(" Sorry... we could not find the render you are looking for. ")])]:(_vm.showOtherErrors)?[_c('p',{staticClass:"library-detail-job__error"},[_vm._v(" Something went wrong: Check with the developers for more info. ")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
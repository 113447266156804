<template>
  <StitchDialog
    :class="[
      'library-detail-job',
      {
        'library-detail-job--error': show404 || showOtherErrors
      }
    ]"
    :visible.sync="showDialog"
    @close="onClose"
  >
    <template v-if="job">
      <!-- TITLE -->
      <div
        slot="title"
        class="library-detail-job__title"
      >
        {{ jobTitle }}
      </div>
      <!-- ------------------------- -->
      <!-- RENDER LIST -->
      <!-- ------------------------- -->
      <div class="library-detail-job__content">
        <div class="library-detail-job__render-list">
          <ElementCard
            v-for="(render, index) in renders"
            :key="index"
            class="library-detail-job__render"
            shadow="hover"
            :body-style="{ padding: '8px' }"
          >
            <div
              class="library-detail-job__render-preview"
              :style="{
                'background-image': `url('${s3trim(
                  render,
                  previewResolution
                )}')`
              }"
              @click="triggerSingleRenderModal(render, index)"
            />
            <a
              class="library-detail-job__render-download"
              :href="render"
              download
              role="button"
              @click="trackDownloads({ renderIndex: index })"
            >
              <i class="el-icon-download library-card-item__actions-download" />
            </a>
          </ElementCard>
        </div>
      </div>
      <!-- ------------------------- -->
      <!-- ACTIONS -->
      <!-- ------------------------- -->
      <div
        slot="footer"
        class="library-detail-job__actions"
      >
        <ElementButton
          type="danger"
          plain
          @click="openConfirmDelete"
        >
          Delete Renders
        </ElementButton>

        <ElementButton
          type="primary"
          plain
          @click="downloadAll"
        >
          Download All Images
        </ElementButton>
      </div>

      <!-- ------------------------- -->
      <!-- SINGLE RENDER DIALOG -->
      <!-- ------------------------- -->
      <StitchDialog
        :visible.sync="showSingleRender"
        append-to-body
        class="library-detail-job__single-job-preview"
        top="5vh"
        @closed="onSingleRenderClose"
      >
        <div class="library-detail-job__single-job-preview-content">
          <img
            class="library-detail-job__single-job-image"
            :src="s3trim(singleRenderUrl)"
            alt="single render"
          >
        </div>
      </StitchDialog>
    </template>
    <template v-else-if="show404">
      <p class="library-detail-job__error">
        Sorry... we could not find the render you are looking for.
      </p>
    </template>
    <template v-else-if="showOtherErrors">
      <p class="library-detail-job__error">
        Something went wrong: Check with the developers for more info.
      </p>
    </template>
  </StitchDialog>
</template>

<script>
// s3Trim, triggerDownload
import { DataUtils } from '@/mixins/utils.js'
import { mapGetters, mapActions } from 'vuex'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import { TRACKER_OBJECTS, TRACKER_EVENTS } from '@/constants/tracker'
import { IMAGE_RESOLUTION } from '@/constants/image'
import VueTypes from 'vue-types'
import { formatToLocaleDateShort } from '@/services/utils'

export default {
  name: 'LibraryDetailJob',

  mixins: [DataUtils],

  props: {
    jobId: VueTypes.number
  },

  data () {
    return {
      showDialog: false,
      showSingleRender: null,
      singleRenderUrl: null,
      previewResolution: IMAGE_RESOLUTION.PREVIEW_JOB,
      show404: false,
      showOtherErrors: false
    }
  },

  computed: {
    ...mapGetters({
      job: 'getJobDetail'
    }),

    /**
     * Download attribute doesn't seem to work at all.
     *
     * @returns {Array} renders
     */
    renders () {
      return this.job.files.map(render => {
        return render.assets_file
      })
    },

    /**
     * @returns {string}
     */
    jobTitle () {
      return `${this.job.name} - ${formatToLocaleDateShort(this.job.createdAt)}`
    }
  },

  async created () {
    if (this.jobId) {
      this.showDialog = true

      try {
        await this.fetchJobDetail(this.jobId)
      } catch (error) {
        if (error.response.status === 404) {
          this.show404 = true
        } else {
          this.showOtherErrors = true
        }
      }
    }
  },

  beforeDestroy () {
    this.showDialog = false
    this.show404 = false
    this.showOtherErrors = false
  },

  methods: {
    ...mapActions(['fetchJobDetail', 'deleteJob', 'getRendersDownloadURL']),

    /**
     * @param {string} renderUrl
     * @param {number} index
     */
    triggerSingleRenderModal (renderUrl, index) {
      this.singleRenderUrl = renderUrl
      this.showSingleRender = true

      this.$tracking.trackEvent({
        object: TRACKER_OBJECTS.RENDER_IMAGE,
        action: TRACKER_EVENTS.VIEWED,
        category: LIBRARY_TYPE.JOB,
        label: `Viewed Render Image ${renderUrl}`,
        job: this.job,
        item: this.job.files[index]
      })
    },

    /**
     */
    async downloadAll () {
      const rendersDownloadUrl = await this.getRendersDownloadURL(this.jobId)
      this.trackDownloads({ downloadType: 'all' })
      this.triggerDownload(rendersDownloadUrl)
    },

    /**
     * @param {object}        payload
     * @param {string | null} [payload.downloadType] - single / all
     * @param {number | null} [payload.renderIndex]  - Render index
     */
    trackDownloads ({ downloadType = 'single', renderIndex = null }) {
      let payload = {
        object: TRACKER_OBJECTS.RENDER,
        action: TRACKER_EVENTS.DOWNLOADED,
        category: LIBRARY_TYPE.JOB,
        label: 'renders - all',
        value: this.jobId,
        item: this.job
      }

      if (downloadType === 'single' && renderIndex !== null) {
        payload = {
          ...payload,
          object: TRACKER_OBJECTS.RENDER_IMAGE,
          label: `render - ${this.jobId}`,
          value: this.job.files[renderIndex].id
        }
      }

      this.$tracking.trackEvent(payload)
    },

    /**
     */
    openConfirmDelete () {
      const jobName = this.job.name

      this.$confirm(
        `This will permanently delete ${jobName}. Continue?`,
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          showClose: false
        }
      )
        .then(() => {
          this.confirmDelete(jobName)
        })
        .catch(() => {})
    },

    /**
     * @param {string} jobName
     */
    async confirmDelete (jobName) {
      await this.deleteJob(this.jobId)

      this.showDialog = false
      this.onClose()

      this.$message({
        showClose: true,
        message: `${jobName} has been deleted`
      })
    },

    /**
     */
    onSingleRenderClose () {
      this.singleRenderUrl = null
    },

    /**
     */
    onClose () {
      let path = this.$route.path
      path = path.split('library-detail')
      path = path[0]

      this.$router.push(
        {
          path
        },
        () => {}
      )
    }
  }
}
</script>

<style lang="scss" scoped>
$preview-image-size: spacing(25);
$dialog-height: 95%;

$job-content-height: 60vh;
$job-preview-height: 70vh;

$error-dialog-width: 40vw;
$error-dialog-height: 70vh;
$error-dialog-margin-top: 10vh;
$icon-download-font-size: 20px;

.library-detail-job /deep/ .el-dialog {
  width: $dialog-height;
}

.library-detail-job--error /deep/ .el-dialog {
  width: $error-dialog-width;
  min-width: spacing(40);
  height: $error-dialog-height;
  max-height: none;
  margin-top: $error-dialog-margin-top;
  margin-bottom: 0;
  padding: 0;
  padding-top: spacing(2);
}

.library-detail-job__title {
  @include text-ellipsis;

  padding: 0 spacing(5) spacing(2) spacing(5);
  text-align: center;
}

.library-detail-job__render-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($preview-image-size, 1fr));
  align-items: center;
  justify-content: space-between;
  justify-items: center;
  padding-bottom: spacing(2);
  column-gap: spacing(2);
  row-gap: spacing(3);
}

.library-detail-job__content {
  width: 100%;
  height: $job-content-height;
  overflow-y: scroll;
}

.library-detail-job__content::-webkit-scrollbar {
  display: none;
}

.library-detail-job__render-download {
  position: absolute;
  right: spacing(1);
  bottom: spacing(1);
  display: none;
  color: $blue;
  font-size: $icon-download-font-size;

  &:hover {
    color: $blue-light;
  }
}

.library-detail-job__render {
  position: relative;
  width: $preview-image-size;
  height: $preview-image-size;
  cursor: pointer;

  &:hover {
    .library-detail-job__render-download {
      display: flex;
    }
  }
}

.library-detail-job__render-preview {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  &::after {
    display: block;
    width: 100%;
    padding-top: 100%;
    content: '';
  }
}

.library-detail-job__single-job-preview-content {
  @include flex-center;

  width: 100%;
  height: $job-preview-height;
}

.library-detail-job__single-job-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.library-detail-job__actions {
  @include flex-center;

  // To override justify-content from the mixin
  justify-content: space-between;
}
</style>
